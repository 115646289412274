// 销售开卡相关api

import request from "@/utils/request";

/**
 * 销售单输入框，获取用户输入的历史数据
 */
export function getUserInputHistory(field) {
  return request({
    url: `/seller/tradeSell/get/historyInFo/${field}`,
    method: 'get',
    loading: false
  })
}

/**
 * 获取企业下店铺列表
 */
export function getShopList(params) {
  return request({
    url: 'seller/shops/getShopChildList',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 获取销售订单记录
 */
export function getSaleOrderList(params) {
  return request({
    url: '/seller/tradeSell',
    method: 'get',
    params
  })
}

/**
 * 获取记录详情
 */
export function getSaleOrderDetail(id) {
  return request({
    url: `/seller/tradeSell/${id}/tradeSellInfo`,
    method: 'get'
  })
}

/**
 * 修改销售订单记录
 */
export function updateSaleOrderRecord(params, id) {
  return request({
    url: `/seller/tradeSell/editTradeSell?id=${id}`,
    method: 'put',
    data: params,
    headers: {'Content-Type': 'application/json'},
  })
}

/**
 * 导入卡号识别卡号
 */

export function exportCard(params) {
  return request({
    url: '/seller/tradeSell/autoIdentify',
    method: 'post',
    data: params,
    headers: {'Content-Type': 'application/json'},
  })
}

/**
 * 根据店铺ID获取卡券列表
 */
export function getShopCardList(id) {
  return request({
    url: `/seller/tradeSell/${id}`,
    method: 'get'
  })
}

/**
 * 编辑卡券数量
 */
export function editCardCouponInfo(params) {
  return request({
    url: '/seller/tradeSell/editCardNumber',
    method: 'post',
    data: params,
    headers: {'Content-Type': 'application/json'},
  })
}

/**
 * 编辑卡券卡号段
 */
export function editCardCouponRange(params) {
  return request({
    url: '/seller/tradeSell/editCardRange',
    method: 'post',
    data: params,
    headers: {'Content-Type': 'application/json'},
  })
}

/**
 * 保存信息
 */
export function saveCardSale(params) {
  return request({
    url: '/seller/tradeSell/addTradeSell',
    method: 'post',
    data: params,
    headers: {'Content-Type': 'application/json'},
  })
}

/**
 * 卡号查询
 */
export function cardSearch(params) {
  return request({
    url: '/seller/card/cardNumList',
    method: 'get',
    params
  })
}

// ------------ 以下为销售退单接口
/**
 * 获取销售退单列表
 */
export function getSaleRefundList(params) {
  return request({
    url: '/seller/refundSell',
    method: 'get',
    params
  })
}

/**
 * 获取销售退单详情
 */
export function getSaleRefundDetail(id) {
  return request({
    url: `/seller/refundSell/${id}/refundSellInfo`,
    method: 'get'
  })
}

/**
 * 根绝销售单号查询详情
 */
export function getSnSaleOrderDetail(sn) {
  return request({
    url: `/seller/refundSell/${sn}/tradeSellInfo`,
    method: 'get',
  })
}

/**
 * 更新销售退单详情
 */
export function updateSaleRefundRecord(params, id) {
  return request({
    url: `/seller/refundSell/editRefundSell?id=${id}`,
    method: 'put',
    data: params,
    headers: {'Content-Type': 'application/json'},
  })
}

/**
 * 销售退单识别卡号段
 */
export function saleRefundAutoIdentify(params) {
  return request({
    url: '/seller/refundSell/autoIdentify',
    method: 'post',
    data: params,
    headers: {'Content-Type': 'application/json'},
  })
}

/**
 * 编辑退单卡号数量
 */
export function editRefundCardNum(params) {
  return request({
    url: '/seller/refundSell/editCardNumber',
    method: 'post',
    data: params,
    headers: {'Content-Type': 'application/json'},
  })
}

/**
 * 编辑退单卡号区间
 */
export function editRefundCardRange(params) {
  return request({
    url: '/seller/refundSell/editCardRange',
    method: 'post',
    data: params,
    headers: {'Content-Type': 'application/json'},
  })
}

/**
 * 添加退单记录
 */
export function addSaleRefundRecord(params) {
  return request({
    url: '/seller/refundSell/addRefundRecordSell',
    method: 'post',
    data: params,
    headers: {'Content-Type': 'application/json'},
  })
}

/**
 * 取消销售订单/退单
 */
export function cancelOrder(order_type, id) {
  return request({
    url: `/seller/tradeSell/cancelOrder?id=${id}&order_type=${order_type}`,
    method: 'get'
  })
}

/**
 * 退单页获取销售单的所有订单号
 */
export function getAllSaleOrderSnList() {
  return request({
    url: '/seller/refundSell/tradeSellSnList',
    method: 'get'
  })
}
