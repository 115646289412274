<template>
  <div>
    <en-table-layout
      :toolbar-two="true"
      :table-data="tableData.data"
      @selection-change="handleSelectionChange"
      ref="enTableLayout"
    >
      <template slot="toolbar">
        <el-form-item label="卡券状态">
          <el-select v-model="searchForm.status" style="width: 110px;" clearable>
            <el-option value="" label="全部"></el-option>
            <el-option value="0" label="未激活"></el-option>
            <el-option value="1" label="已激活"></el-option>
            <el-option value="2" label="已禁用"></el-option>
            <el-option value="3" label="已绑定"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="卡号区间" class="col-auto" ref="cardRange" :error="cardRangeError">
          <el-input
            style="width: 100px;"
            v-model.trim="searchForm.card_code_start"
            clearable
            @blur="handleCardRange()"
          />
          <span style="margin: 0 5px;">-</span>
          <el-input
            style="width: 100px;"
            v-model.trim="searchForm.card_code_end"
            clearable
            @blur="handleCardRange()"
          />
        </el-form-item>
        <el-form-item label="操作日期" class="col-auto">
          <el-date-picker
            style="width: 220px"
            v-model="order_time_range"
            type="daterange"
            align="center"
            size="medium"
            :editable="false"
            unlink-panels
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item class="col-auto">
          <el-input
            style="width: 260px"
            v-model.trim="searchForm.keywords"
            placeholder="请输入关键词"
            clearable
          >
            <el-select style="width: 110px;" v-model="search_type" slot="prepend">
              <el-option label="卡券名称" value="card_name"></el-option>
              <el-option label="商城名称" value="shop_name"></el-option>
              <el-option label="客户名称" value="company"></el-option>
              <el-option label="销售人员名称" value="active_salesman"></el-option>
              <el-option label="关联销售单号" value="sell_trade_sn"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-button size="small" type="primary" @click="saleCardRefreshPage('search')">搜索</el-button>
      </template>
      <template slot="toolbar-two">
        <el-button size="small" type="primary" @click="saleCardRefreshPage('exportCardList', 0)">导出全部</el-button>
        <el-button size="small" type="primary" @click="saleCardRefreshPage('exportCardList', 1)">导出勾选项</el-button>
      </template>
      <template slot="table-columns">
        <el-table-column align="center" type="selection" width="55" fixed />
        <el-table-column align="center" label="编号" width="60" fixed>
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="卡券名称" width="220" show-overflow-tooltip prop="card_name" fixed />
        <el-table-column align="center" label="所属商城" prop="shop_name" show-overflow-tooltip />
        <el-table-column align="center" label="卡号" width="120" prop="card_code" show-overflow-tooltip />
        <el-table-column align="center" label="卡券状态">
          <template slot-scope="scope">
            {{
              scope.row.statuses === 1 ? '已激活'
                : scope.row.statuses === 2 ? '未激活'
                  : scope.row.statuses === 3 ? '已绑定' : '已禁用'
            }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作日期" width="150">
          <template slot-scope="scope">
            {{ scope.row.update_time | unixToDate }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="面值" prop="card_value" width="100" />
        <el-table-column align="center" label="客户名称" prop="company" show-overflow-tooltip />
        <el-table-column align="center" label="销售人员名称" show-overflow-tooltip prop="active_salesman" width="120" />
        <el-table-column align="center" label="关联销售单号" prop="sell_trade_sn" width="180" fixed="right"/>
      </template>
      <el-pagination
        slot="pagination"
        @size-change="saleCardRefreshPage('handlePageSizeChange', $event)"
        @current-change="saleCardRefreshPage('handlePageCurrentChange', $event)"
        :current-page="tableData.page_no"
        :page-size="tableData.page_size"
        :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout"
        background
        :total="tableData.data_total"
      />
    </en-table-layout>
  </div>
</template>

<script>
import EnTableLayout from "@/../ui-components/TableLayout/src/main";
import * as API_Sale from '@/api/saleCard';
import {Foundation} from "../../../../ui-utils";
import {handleDownload} from "@/utils";
export default {
  name: "cardNoSearch",
  components: {
    EnTableLayout
  },
  data() {
    return {
      search_type: 'card_name',
      order_time_range: '',
      params: {
        page_no: 1,
        page_size: 20
      },
      searchForm: {
        card_code_start: '',
        card_code_end: '',
        status: '',
        keywords: ''
      },
      tableData: {},
      cardRangeError: '',
      multipleSelection: []
    }
  },
  mounted() {
    this.getList();
  },
  watch: {
    'searchForm.card_code_start'(newVal, oldVal) {
      if (!newVal && !this.searchForm.card_code_end) {
        this.cardRangeError = ''
      }
    },
    'searchForm.card_code_end'(newVal, oldVal) {
      if (!newVal && !this.searchForm.card_code_start) {
        this.cardRangeError = ''
      }
    },
  },
  methods: {
    // 卡号区间校验
    handleCardRange() {
      /** 判断输入的卡号是否包含前缀 **/
      const cardReg = /^[a-zA-Z]+\d{3,}$|^\d{3,}$/
      const letterReg = /^[a-zA-Z]+\d+$/
      if (this.searchForm.card_code_start && this.searchForm.card_code_end) {
        if (cardReg.test(this.searchForm.card_code_start) && cardReg.test(this.searchForm.card_code_end)) {
          // 包含前缀
          if (letterReg.test(this.searchForm.card_code_start) && letterReg.test(this.searchForm.card_code_end)) {
            const startPrefix = /[a-zA-Z]+/.exec(this.searchForm.card_code_start)[0]
            const endPrefix = /[a-zA-Z]+/.exec(this.searchForm.card_code_end)[0]
            if (startPrefix !== endPrefix) {
              this.cardRangeError = '当前卡号区间格式不一致。'
            } else if (startPrefix.length > 5 || endPrefix.length > 5) {
              console.log('卡号前缀大于五位数')
              this.cardRangeError = '当前卡号区间格式不正确，请重新填写卡号区间。'
            } else {
              // 判断数组部分大于3位是否以0开头
              const startNum = /\d+/.exec(this.searchForm.card_code_start)[0];
              const endNum = /\d+/.exec(this.searchForm.card_code_end)[0];
              console.log(startNum, endNum, /\d+/.exec(this.searchForm.card_code_start))
              this.cardRangeError = ''
              // if (/^0\d{3,}/.test(startNum) || /^0\d{3,}/.test(endNum)) {
              //   console.log('大于3位以0开头')
              //   this.cardRangeError = '当前卡号区间格式不正确，请重新填写卡号区间。'
              // } else {
              //   this.cardRangeError = ''
              // }
            }
          } else if ((letterReg.test(this.searchForm.card_code_start) && !letterReg.test(this.searchForm.card_code_end)) ||
            (!letterReg.test(this.searchForm.card_code_start) && letterReg.test(this.searchForm.card_code_end))) {
            this.cardRangeError = '当前卡号区间格式不一致。'
          } else {
            if (parseInt(this.searchForm.card_code_end) < parseInt(this.searchForm.card_code_start)) {
              this.cardRangeError = '结束框卡号数字部分不能小于起始输入框卡号数字部分。'
            } else if (/^0\d{3,}/.test(this.searchForm.card_code_end) || /^0\d{3,}/.test(this.searchForm.card_code_start)) {
              // 满足3位以上数字且以0开头
              console.log('小于3位以0开头')
              this.cardRangeError = '当前卡号区间格式不正确，请重新填写卡号区间。'
            } else {
              this.cardRangeError = ''
            }
          }
        } else {
          console.log('不包含前缀')
          this.cardRangeError = '当前卡号区间格式不正确，请重新填写卡号区间。'
        }
      } else if (!this.searchForm.card_code_start && !this.searchForm.card_code_end) {
        this.cardRangeError = ''
      } else {
        console.log('不包含前缀')
        this.cardRangeError = '请输入正确卡号区间进行搜索'
      }
    },
    search() {
      this.params ={
        page_no: 1,
        page_size: this.params.page_size
      }
      if (this.cardRangeError) {
        this.$message.error('请先输入正确的卡号区间再搜索');
        return;
      }
      if ((this.searchForm.card_code_start && this.searchForm.card_code_end) && (this.searchForm.status || this.searchForm.keywords || this.order_time_range)) {
        this.$message.error('卡号区间搜索不能和其他筛选条件同时使用');
        return;
      }
      this.params['card_code_start'] = this.searchForm.card_code_start
      this.params['card_code_end'] = this.searchForm.card_code_end
      switch (this.searchForm.status) {
        case '0':
          this.params['active_status'] = 0
          this.params['binding_status'] = 0
          break;
        case '1':
          this.params['active_status'] = 1
          this.params['binding_status'] = 0
          break;
        case '2':
          this.params['active_status'] = 0
          this.params['binding_status'] = 1
          break;
        case '3':
          this.params['active_status'] = 1
          this.params['binding_status'] = 1
          break;
        case '':
          delete this.params['active_status']
          delete this.params['status']
          delete this.params['binding_status']
      }
      delete this.params['startTime']
      delete this.params['endTime']
      if (this.order_time_range) {
        this.params['startTime'] = this.order_time_range[0].getTime() / 1000
        this.params['endTime'] = this.order_time_range[1].getTime() / 1000 + 86399
      }
      if (this.searchForm.keywords) {
        this.params[this.search_type] = this.searchForm.keywords
      } else {
        delete this.params[this.search_type]
      }
      this.getList();
    },
    getList() {
      API_Sale.cardSearch(this.params).then(res => {
        this.tableData = res
      })
    },
    handlePageSizeChange(size) {
      this.params.page_no = 1;
      this.params.page_size = size;
      this.getList();
    },
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.getList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    exportCardList(type) { // 0 导出全部 1 导出选中
      let params = {
        page_no: 1,
        ...this.params
      }
      params.page_size = 999999
      const filterVals = [
        "card_name",
        "shop_name",
        "card_code",
        "status",
        "update_time",
        "card_value",
        "company",
        "active_salesman",
        "sell_trade_sn",
      ];
      const tHeaders = [
        "卡券名称",
        "商城名称",
        "卡号",
        "卡券状态",
        "操作日期",
        "面值",
        "客户名称",
        "销售人员",
        "关联销售单号",
      ];
      if (type === 1) {
        if (!this.multipleSelection.length) {
          this.$message.error('请先选择要导出的数据');
          return false;
        }
        params['ids'] = this.multipleSelection.map(item => {return item.id})
      }
      API_Sale.cardSearch(params).then(res => {
        let data = res.data.map(item => {
          return {
            card_name: item.card_name,
            shop_name: item.shop_name,
            card_code: item.card_code,
            status: item.statuses === 1 ? '已激活' : item.statuses === 2 ? '未激活' : item.statuses === 3 ? '已绑定' : '已禁用',
            update_time: Foundation.unixToDate(item.update_time),
            card_value: item.card_value,
            company: item.company,
            active_salesman: item.active_salesman,
            sell_trade_sn: item.sell_trade_sn
          }
        })
        handleDownload(data, tHeaders, filterVals, "卡券列表");
        this.$refs.enTableLayout.clearSelected();
      })
    }
  }
}
</script>

<style scoped>

</style>
